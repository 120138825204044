.tag{
    display:flex;
    flex-wrap:wrap;
    user-select: none;
    >.tag-item{
        background:var(--color-black-10);
        padding:8px;
        border-radius:3px;
        cursor:pointer;
        font:var(--font-text5-14);
        margin-right:8px;
        margin-bottom:8px;
        color:var(--color-black-90);
        &:hover{
            background:var(--color-black-5);
        }
        &.active{
            background-color: var(--color-blue-500);
            color:var(--color-white);
        }
    }
}