.mail-top{
    display:grid;
    grid-template-columns: 0fr auto auto 0fr;
    grid-gap:10px;
}

/* message*/
.mail-foto{
    height:40px;
    width:40px;
    overflow: hidden;
    border-radius:100%;
}

.mail-foto img{
    height:100%;
    width:100%;
    object-fit: cover;
}

.mail-user{
    display:flex;
    grid-gap:10px;
    align-items: center;
}

/*message*/
.mail-mail{
    margin:16px 0px;
    padding:25px 32px 25px 17px;
    background:var(--color-blue-50);
    max-height:70vh;
}

.mail-data{
    text-align: right;
    font:var(--font-text15-14);
    color:var(--color-black-50);
}



// messages
.mail-mes-item{
    margin-bottom:30px;
}
.mail-mes{
    display:grid;
    grid-template-columns: 0fr auto 0fr;
    align-items: center;
    grid-gap:20px;
}

.mail-mes-text{
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-left:60px;
}

.mail-mes-data{
    white-space: nowrap;
}

.mail-mes-block{
    padding:24px;
    border-bottom:1px solid #ddd;
}

// append files
.mail-mes-append{
    display:flex;
    justify-content: flex-start;
    grid-gap:10px;
    flex-wrap: wrap;
    padding:10px 24px;
}

.mail-mes-doc{
    display:inline-flex;
    align-items: center;
    font:var(--font-text5-14);
    >a{
        color:var(--color-black-70);
    }
}

/* form*/
.mail-form{
    display:grid;
    grid-template-columns: auto 0fr;
    grid-gap:20px;
    margin:20px 0px;
}

.mail-attach{
    padding:12px 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 10px;
    font:var(--font-text5-14);
}