@import '../../css/var.scss';
/*about-text*/
.about-text{
    text-align:left;
    margin:10px auto;
}

.about-content{
    padding-top:70px;
}

.about-title{
    text-align:center;
    margin-bottom:32px;
    .about-flag &{
        margin-top:80px;
    }
}
/*banner*/
.about-banner{
    background:url(./img/about/backgroud.jpeg) no-repeat 50% 50%;
    background-size:cover;
    position:relative;
    text-align:center;
    padding:80px 0;
    color:#fff;
}

.about-banner-block{
    position:relative;
    z-index: 1;
}

.about-banner:before{
    top:0;
    left:0;
    background:var(--color-dark-500);
    opacity:.85;
    display:block;
    content:'';
    height:100%;
    width:100%;
    z-index:0;
    position:absolute;
}

.about-banner-line{
    background-color:var(--color-blue-500);
    height:3px;
    width:50%;
    margin:40px auto;
}

/* cart*/
.about-cart{
    background:url(./img/about/komas.svg) 40px 60px no-repeat #FFFFFF;
    background-size: 110px auto;
    box-shadow: 0px 20px 40px rgba(50, 95, 210, 0.1);
    border-radius: 10px;    
    padding:40px 40px 40px 200px;
    color:#3F4E77;
    @media(max-width:$pad){
        background: none;
        padding:40px;
    }
}

/*flag*/
.about-flag{
    position:relative;
    >img{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        z-index: -1;
    }
}
.about-flag-text{
    padding-right: 60px;
    @media(max-width:$pad){
        padding-right: 0px
    }
}

.about-nout{
    margin-top:40px;
}

/*sequrity*/
.about-sequrity{
    position:relative;
    padding:120px 0px 40px;
    color:var(--color-white);
    z-index:1;
    @media(max-width:$pad){
        margin-top:40px;
        text-align: center;
    }
}

.about-sequrity:after,
.about-sequrity:before{
    display:block;
    position:absolute;
    width:50%;
    height:100%;
    content:'';
    background:var(--color-dark-500);
    top:0;
}

.about-sequrity:after{
    left:0;
    transform:skewY(7deg);
    transform-origin: left;
}

.about-sequrity:before{
    right:0;
    transform:skewY(-7deg);
    transform-origin: right;
}

.about-sequrity-block{
    position:relative;
    z-index:1;
    display:flex;
    grid-gap:60px;
    align-items: center;
    @media(max-width:$pad){
        flex-direction: column;
    }
}

.about-sequrity-title{
    margin-bottom:40px;
}

.about-sequrity-icon{
    flex:0 0 180px;
    @media(max-width:$pad){
        flex:auto;
        height: 200px;
    }
}
.about-sequrity-icon img{
    object-fit:contain;
    height:100%;
    width: 100%;
}

/*about web*/
.about-web{
    position:relative;
    background: url('./img/about/background_line.jpg') no-repeat 0% 0% #F7F8FA;
    background-size:100% auto;
    padding-top:200px;
    padding-bottom: 80px;
}

.about-web-text{
    width:50%;
    @media(max-width:$pad){
        width: 100%;
    }
}

/* about feedback*/
.about-feedback{
    margin:80px auto;
}

.about-feedback-block{
    display:flex;
    align-items:center;
    grid-gap:80px;
    @media(max-width:$pad){
        flex-direction: column;
    }
}

.about-feedback-foto{
    flex:3;
}

.about-feedback-text{
    flex:2;
}

.about-feedback-foto img{
    width:100%;
    border-radius: 10px;
    box-shadow: 0px 20px 30px rgba(42, 62, 114, 0.2);
}

/* about bottom*/

.about-form{
    background: #FFFFFF;
    box-shadow: 0px 20px 40px rgba(50, 95, 210, 0.1);
    border-radius: 10px;
    padding:80px 40px 80px 80px;
    display:flex;
    position: relative;
    grid-gap:60px;
    @media(max-width:$pad){
        flex-direction: column-reverse;
        padding:60px 40px;
    }
    .button{
        width:100%;
    }
}

.about-form-block{
    flex:1;
}

.about-form-input{
    margin-bottom:24px;
}

.about-form-text{
    flex:1;
}

// bottom

.about-bottom-text{
    color: #fff;
    width: 100%;
    background:#232323;
    border-radius:20px;
    padding:30px;
    padding-bottom: 40px;
    max-width:700px;
    margin:0 auto;
    text-align: center;
}

.about-bottom-flag{
    background:url(/img/page/about/flag.jpeg) no-repeat 50% 100%;
    background-size:100% 70%;
    background-position: center;
    margin:60px 0px;
    padding:0 20px;
}
