.fr-pf{
    display:flex;
    align-items: flex-start;
    grid-gap:30px;
}

.fr-pf-save{
    position:sticky;
    top:80px;
}

.fr-pf-content{
    flex:1;
}

.fr-pf-title{
    margin:20px 0px;
    &:first-child{
        margin-top:0;
    }
}

/* top */
.fr-pf-top{
    display:flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom:25px;
    grid-gap:20px;
}

.fr-pf-foto{
    overflow: hidden;
    height:140px;
    width:140px;
    flex: 0 0 140px;
    border-radius:100%;
    background:#f0f0f0;
    display:flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.fr-pf-foto img{
    height:100%;
    width:100%;
    object-fit: cover;
}

/* form*/
.fr-pf-form{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:30px;
}

.fr-pf-form ._span{
    grid-column: 1 / span 2;
}

/* range*/
.fr-pf-time{
    display:grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap:30px;
    align-items: center;
}

