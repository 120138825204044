@import './var.scss';
/* form*/
.form{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:20px;
    margin:20px 0px;
    >._span{
        grid-column: 1 / span 2;
    }
    &._tree{
        grid-template-columns: 1fr 1fr 1fr;
    }
    &._tree>._span{
        grid-column: 1 / span 3;
    }

    &._button{
        grid-gap:10px 20px;
    }

    &._button .button{
        width: 100%;
    }
    @media(max-width:$pad){
        &,&._tree{
            grid-template-columns: 1fr;
        }
        >._span,&._tree>._span{
            grid-column: 1 / auto;
        }
    }
}
