@import "def.scss";
@import "css/animate.scss";
@import "css/var.scss";
@import "css/color.scss";
@import "css/fonts.scss";
@import "css/inputs.scss";
@import "css/icons.scss";
@import "css/checkbox.scss";
@import "css/navi.scss";
@import "css/char.scss";
@import "css/card.scss";
@import "css/cardItem.scss"; //?
@import "css/cardStat.scss";
@import "css/title.scss";
@import "css/section.scss";
@import "css/select.scss";
@import "css/table.scss";
@import "css/form.scss";
@import "css/swiper.scss";
@import "css/pag.scss";
@import "css/item.scss";
@import "css/pr.scss";
@import "css/label.scss";
@import "css/tags.scss";
@import "css/tagslist.scss";
@import "css/back.scss";
@import "css/overflow.scss";
@import "css/tooltip.scss";
@import "css/button.scss";
@import "css/logo.scss";
@import "css/alert.scss";
@import "css/mes.scss";
@import "css/mailatach.scss";

//? maybe components
@import "css/rubric.scss";
@import "css/search.scss";

@import "css/circle.scss";
@import "css/slider.scss";

// my default css
*{
    outline:none;border:0px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    //user-select:none;
}


img{
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

html,body{
    padding:0;
    margin:0;
    height:100%;
    width:100%;
    display: block;
    font:var(--font-text3-18);
    color: #222222;
    background: #fff;
}

a,.href{
    text-decoration:none;
    color:var(--color-blue-500);
    cursor:pointer;
}

div{
    display:block;
}

h1,h2,h3,h4,h5,h6,h7{
    font-weight:normal;
    margin: 0;
}

form{
    padding:0px;
    margin:0px;
    border:0px
}

ul {
    list-style: none; /* Remove default bullets */
    padding-left:18px;
    display:flex;
    flex-direction: column;
    grid-gap:1em;
    margin:10px 0px;
}

ul li{
    word-break: break-all;
}

ul li::before {
    position:absolute;
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color:rgba(37, 175, 248, 0.5); /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    flex:0 0 20px; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
    font-size:20px;
    height:1em;
    display:flex;
    align-items: center;
}