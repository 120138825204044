@import '../../css/var.scss';
.services-block{
    background:#F7F8FA;
    .button{
        margin-top:40px;
    }
}
.services-banner{
    background:url(./img/banner.jpeg) no-repeat 50% 50%;
    background-size:cover;
    position:relative;
    padding:32px 0px 60px;
    color:#fff;
}

.services-banner:after{
    position: absolute;
    z-index:0;
    background: var(--color-dark-500);
    content: '';
    display:block;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity: 0.85;
}

.services-banner-block{
    position: relative;
    z-index:1;
}

.services-banner-title{
    text-align:center;
    margin:30px auto;
}

.services-banner-name{
    text-align:center;
}

.services-banner-icons{
    display: flex;
    grid-gap:40px;
    justify-content:center;
    @media(max-width:$pad){
        align-items: flex-start;
        flex-direction: column;
    }
}

.services-banner-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap:20px;
    @media(max-width:$pad){
        flex-direction: row;
        align-items: center;
    }
}

.services-banner-graf{
    height:80px;
    width:80px;
    background-position:center;
    background-size:contain;
    background-repeat: no-repeat;
    @media(max-width:$pad){
        height:40px;
        width:40px;
    }
}

.services-banner-graf._user{
    background-image:url(./img/ico1.png);
}

.services-banner-graf._stat{
    background-image:url(./img/ico2.png);
}

.services-banner-graf._play{
    background-image:url(./img/ico3.png);
}

.services-banner-graf._true{
    background-image:url(./img/ico4.png);
}

/* buy*/
.services-buy{
    padding:40px 0px 0px;
}

.services-buy-pack{
    display:flex;
    grid-gap:16px;
    @media(max-width:$pad){
        flex-direction: column;
    }
}

.services-buy-sale{
    color:var(--color-blue-500);
    font:var(--font-h3b-24);
    width:70%;
    padding-bottom:20px;
}

.services-buy-item{
    flex:1;
    display: flex;
    flex-direction: column;
    background:#fff;
    position: relative;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
}

.services-buy-free{
    left:50%;
    position: absolute;
    color:var(--color-blue-500);
    font-weight: 700;
    font-size: 45px;
    text-transform: uppercase;
    transform: rotate(-30deg) translate(-50%,0px);
    opacity:0.5;
}

.services-buy-title{
    border-bottom:1px solid #C4C4C4;
    padding:32px;
    >div{
        display:flex;
        justify-content: space-between;
        align-items: baseline;
    }
}

.services-buy-name{
    font:var(--font-h1-40);
    &._premium{
        color:var(--color-blue-500);
        font:var(--font-h1b-50);
    }
}

.services-buy-monce{
    margin-top:5px;
}

.services-buy-content{
    padding:32px;
    flex:1;
}

.services-buy-content>div{
    margin-bottom:16px;
}

.services-buy-content>div._no{
    opacity:0.5;
    text-decoration: line-through;
}

/*text*/
.services-text{
    margin:32px auto 60px;
}

.services-button{
    padding:0px 32px 10px;
    .button{
        width: 100%;
    }
}