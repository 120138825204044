/*char*/
.char{
    display:flex;
    flex-wrap: wrap;
    grid-gap:3px;
}
.char-item{
    background:#808080;
    padding:4px 6px;
    border-radius:5px;
    cursor:pointer;
    &.disabled{
        opacity:0.5;
        pointer-events:none;
    }
}

.char-item:hover,
.char-item.active{
    background:var(--color-blue-500);
}