@import '/src/var.scss';

.side-menu{
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:8px;
    flex: 0 0 260px;
    position:relative;
    @media(max-width:$pad){
        flex:auto;
    }
}

// .site-menu-modile{
//     cursor:pointer;
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     height: 48px;
//     display:none;
//     @media(max-width:$pad){
//         display: block;
//     }
// }

.side-menu-item{
    padding: 10px 15px;
    background-color:var(--color-black-5);
    border-radius:2px;
    display:flex;
    align-items: center;
    cursor:pointer;
    @media(max-width:$pad){
        display:none;
        .side-menu.active &{
            display:flex;
        }
        &.active{
            display:flex;
        }
        &.show{
            display:flex;
        }
    }
}

.side-menu-item:hover,
.side-menu-item.active{
    background-color:var(--color-black-10);
}

/*item ico*/

.side-menu-icon{
    display:flex;
    align-items: center;
    flex: 0 0 24px;
    margin-right:10px;
}

.side-menu-item .icons{
    background-color: var(--color-blue-500);
}

.side-menu-item:hover .icons,
.side-menu-item.active .icons{
    background-color: var(--color-dark-500);
}

/*item text*/

.side-menu-text{
    font:var(--font-button2-13);
    color: var(--color-black-90);
    text-transform: uppercase;
    flex:1;
    // @media (max-width:$pad){
    //     display: none;
    // }
}

.side-menu-item:hover .side-menu-text,
.side-menu-item.active .side-menu-text{
    color: var(--color-black-50);
}

.icons._m-bag{-webkit-mask-image: url(/img/icons/menu/bag.svg);}
.icons._m-plus{-webkit-mask-image: url(/img/icons/menu/plus.svg);}
.icons._m-arhive{-webkit-mask-image: url(/img/icons/menu/arhive.svg);}
.icons._m-people{-webkit-mask-image: url(/img/icons/menu/people.svg);}
.icons._m-user{-webkit-mask-image: url(/img/icons/menu/man.svg);}
.icons._m-money{-webkit-mask-image: url(/img/icons/menu/money.svg);}
.icons._m-spam{-webkit-mask-image: url(/img/icons/menu/spam.svg);}
.icons._m-paper{-webkit-mask-image: url(/img/icons/menu/doc.svg);}
.icons._m-home{-webkit-mask-image: url(/img/icons/menu/home.svg);}
.icons._m-company{-webkit-mask-image: url(/img/icons/menu/company.svg);}
.icons._m-question{-webkit-mask-image: url(/img/icons/menu/question.svg);}
.icons._m-mail{-webkit-mask-image: url(/img/icons/menu/mail.svg);}
.icons._m-config{-webkit-mask-image: url(/img/icons/menu/settings.svg);}
.icons._m-stat{-webkit-mask-image: url(/img/icons/menu/diagram.svg);}
.icons._m-eye{-webkit-mask-image: url(/img/icons/menu/preview.svg);}
.icons._m-out{-webkit-mask-image: url(/img/icons/menu/logout.svg);}