.label{
    border-radius:4px;
    padding:4px 8px;
    font:var(--font-button2-13);
    display: inline-block;
    text-transform: uppercase;
}

.label._free{
    background:#38C55F;
    color:#fff;
}

.label._wait{
    background:#FCB11F;
    color:#fff;
}

.label._no{
    background:#D93939;
    color:#fff;
}

/*line*/
.label._free._line{
    background:transparent;
    border:2px solid #38C55F;
    color:#38C55F;
}

.label._wait._line{
    background:transparent;
    border:2px solid #FCB11F;
    color:#FCB11F;
}

.label._no._line{
    background:transparent;
    border:2px solid #D93939;
    color:#D93939;
}