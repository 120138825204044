@import '/src/var.scss';
.fr-mn{
    margin: 32px 0px;
    display:flex;
    align-items: flex-start;
    justify-content: stretch;
}

.fr-mn-top{
    font:var(--font-h3-18);
    background:var(--color-black-5);
    color:var(--color-black-50);
    display:flex;
    justify-content: center;
}

.fr-mn-top-flex{
    display:flex;
    justify-content: center;
    grid-gap:40px;
    align-items: center;
}

.fr-mn-menu{
    flex: 0 0 260px;
    margin-right: 30px;
}

.fr-mn-content{
    flex:1;
}

.fr-mn-title{
    font:var(--font-h2b-32);
    display:flex;
    grid-gap:15px;
    margin-bottom:30px;
}

.fr-mn-img{
    display:block;
    overflow: hidden;
    height:32px;
    width: 32px;
    border-radius: 100%; 
}

.fr-mn-img img{
    height:100%;
    width: 100%;
    object-fit: cover;
}

.fr-mn-status{
    font:var(--font-h5b-18);
    color: var(--color-yellow-lable);
    margin-top:5px;
}

.fr-mn-status._pr{
    color: var(--color-green-lable);
}

.fr-mn-cards{
    display:grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap:24px;
    @media(max-width:$pad){
        grid-template-columns: repeat(2,1fr);
    }
    @media(max-width:$mobile){
        grid-template-columns: 1fr;
    }
}