.rat-mes{
    width:100%;
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:30px;
}

.res-mes-grid{
    display:grid;
    grid-template-columns: 1fr 200px;
    grid-gap:20px;
    @media(max-width:768px){
        grid-template-columns: 1fr;
        grid-gap:10px;
        align-items: center;
        justify-content: center;
        >div{
            text-align: center;
        }
    }
}

.rat-mes-stars{
    display:flex;
    justify-content: flex-end;
    align-items: center;
    @media(max-width:768px){
        justify-content: center;
    }
}