:root{
    --color-blue-50:#E5F3FF;
    --color-blue-100:#CCE6FF;
    --color-blue-200:#99CEFF;
    --color-blue-300:#66B5FF;
    --color-blue-light:#339DFF;
    --color-blue-500:#0084FF;
    --coloe-blue-600:#006ACC;
    --color-blue-700:#004F99;
    --color-blue-800:#003566;
    --color-blue-900:#001A33;
    
    --color-dark-50:#F2F4FA;
    --color-dark-30:#C4C4C4;
    --color-dark-100:#DFE4F2;
    --color-dark-200:#B7C1DC;
    --color-dark-300:#6D7CA3;
    --color-dark-400:#3F4E77;
    --color-dark-500:#272D3D;
    --color-dark-600:#111933;
    --color-dark-700:#040D28;
    --color-dark-800:#01071C;
    --color-dark-900-black:#080808;
    
    --color-black-90:#222222;
    --color-black-70:#4D4D4D;
    --color-black-50:#808080;
    --color-black-30:#B3B3B3;
    --color-black-10:#E6E6E6;
    --color-black-5:#F2F2F2;
    
    --color-white:#ffffff;

    --color-green-lable:#38C55F;
    --color-yellow-lable:#FCB11F;
    --color-red-lable:#D93939;

    --color-blue-icon:#C8DFEC;

}