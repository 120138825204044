@import '../var.scss';
.button{
    cursor:pointer;
    display:inline-flex;
    align-items: center;
    text-transform: uppercase;
    padding:16px 32px;
    border-radius:4px;
    text-align:center;
    justify-content: center;
    white-space:nowrap;
    overflow:hidden;
    position:relative;
    text-overflow:ellipsis;
    @media(max-width:$pad){
        //padding:10px !important;
        white-space: unset;
    }
    .form &{
        //width:100%;
    }
    &._width{
        width:100%;
    }
    &._form{
        padding:10px 32px;
    }
}

/*primary*/

.button-primary{
    background:var(--color-blue-500);
    color:var(--color-white);
    font:var(--font-button1-16);
    text-align:center;
    cursor:pointer;
    &:hover{
        box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.2);
    }
    &:focus{
        background:var(--coloe-blue-600);
    }
    &.disabled{
        background:#E5F3FF;
        cursor: default;
        color:var(--color-blue-icon);
    }
    &._green{
        background:var(--color-green-lable);
    }
}

/*secondary*/
.button-secondary{
    border:2px solid var(--color-blue-500);
    color:var(--color-blue-500);
    font:var(--font-button1-16);
    background:var(--color-white);
    &._noline{
        border-color:#fff;
        &:hover{
            border-color:var(--color-blue-500);
        }
    }
    &:hover{
        background:var(--color-blue-500);
        color:var(--color-white);
        // border: 2px solid transparent;
        // box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.2);
    }
    &:active{
        background:var(--coloe-blue-600);
    }
    &.disabled{
        border:2px solid var(--color-blue-50);
        color:var(--color-blue-50);
        cursor: default;
    }
}

/*line*/

.button-line{
    color:var(--color-blue-500);
    background:var(--color-white);
    font:var(--font-button1-16);
    border-radius: 4px;
    &:hover{
        border:none;
        color:var(--color-blue-500);
        // box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.25);
    }
    &:active{
        color:var(--coloe-blue-600);
    }
    &.disabled{
        color:var(--color-blue-icon);
        cursor: default;
    }
}

/*small*/

.button-small{
    position:relative;
    padding:8px 20px;
    border-radius:4px;
    border:1px solid var(--color-blue-500);
    color:var(--color-blue-500);
    font:var(--font-button1-16);
    background:#fff;
    &:hover{
        background:var(--color-blue-500);
        color:var(--color-white);
        box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.2);
    }
    &:active{
        background:var(--coloe-blue-600);
    }
    &.disabled{
        color:var(--color-blue-icon);
        border:1px solid var(--color-blue-icon);
        cursor: default;
    }
    &:before{
        content: '';
        height:24px;
        width: 24px;
        margin-left:-10px;
        margin-right:5px;
        display: block;
        background-repeat:no-repeat;
        background-position:center;
        background-size:contain;
    }
    &.disables:before{
        filter:grayscale(1);
    }
    &._minus:before{
        background-image: url(/img/icons/minus.svg);
    }
    &._minus:hover:before{
        background-image: url(/img/icons/minus-white.svg);
    }
    &._plus:before{
        background-image:url(../img/icons/plus.svg);
    }
    &._plus:hover:before{
        background-image:url(/img/icons/plus-white.svg);
    }
    &._download{
        &._active{
            background:var(--color-green-lable);
            border-color:var(--color-green-lable);
            color:#fff;
        }
        &._active:before{
            background-image:url(../img/icons/download-white.svg);
        }
        &:before{
            background-image:url(../img/icons/download.svg);
        }
    }
    &._download:hover:before{
        background-image:url(../img/icons/download-white.svg);
    }
    &._no{
        padding:5px 12px;
    }
}

/* main button search*/
.button-search{
    font:var(--font-button1-16);
    background:#f0f0f0;
    color: #0F8BFF;
    display:flex;
    font-size:16px;
    align-items: center;
    height:100%;
    border-left:1px solid #ccc;
    &:after{
        background:url(/img/main/search.svg) no-repeat 50% 50%;
        background-size: 100%;
        height:20px;
        width:20px;
        position:absolute;
        left:7px;
        content:'';
        display:block;
    }
    &:hover{
        background:var(--color-blue-500);
        border-color:var(--color-white);
        color:var(--color-white);
    }
    &:active{
        background:var(--coloe-blue-600);
        border-color:var(--color-white);
        color:var(--color-white);
    }
    &:hover:after{
        filter:brightness(100);
    }
    &:active:after{
        filter:brightness(100);
    }
}

/* register */
.button-registrieren{
    background:var(--color-green-lable);
    border-radius:4px;
    color:var(--color-white);
    font:var(--font-button1-16);
}


/* social buttons */
.button-soc{
    padding:16px 50px 16px 80px;
    border-radius:4px;
    background:#3579EA;
    color:#fff;
    font:var(--font-button1-16);
    display:flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    &:before{
        content:'';
        height:24px;
        width:24px;
        margin-right:10px;
        background-position: center;
        background-size:contain;
        background-repeat: no-repeat;
    }
    &._fb:before{
        background-image: url(/img/button/fb.svg);
    }
    &._gl:before{
        background-image: url(/img/button/gl.svg);
    }
    @media(max-width:$pad){
        padding:10px;
    }
}

/* button menu*/
.button-menu{
    padding:20px 30px;
    border-radius:4px;
    background:#3579EA;
    color:#fff;
    font:var(--font-button1-16);
    display:flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: none;
}