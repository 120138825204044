.card-stat{
    font:var(--font-text6-13);
    background: #FFFFFF;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding:20px;
    display:grid;
    grid-gap:5px;
    grid-template-columns: 1fr;
    text-align: center;
    width:100%;
    height:100%;
    color: var(--color-black-50);
}

.card-stat-foto{
    height:80px;
    width:80px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background:#f0f0f0;
    border-radius: 100%;
    margin: 0 auto;
    margin-bottom:10px;
}

.card-stat-foto img{
    height:100%;
    width:100%;
    object-fit: cover;
}

.card-stat-title{
    font:var(--font-h7b-14);
    font-weight: 700;
    color: var(--color-black-90);
}