@import '/src/var.scss';
.fr{
    margin: 32px 0px;
    display:flex;
    align-items: flex-start;
    justify-content: stretch;
    grid-gap:30px;
    @media(max-width:$pad){
        flex-direction: column;
        align-items: initial;
    }
}

.fr-top{
    font:var(--font-h3-18);
    background:var(--color-black-5);
    color:var(--color-black-50);
    display:flex;
    justify-content: center;
}

.fr-top-flex{
    display:flex;
    justify-content: center;
    grid-gap:40px;
    align-items: center;
}

.fr-content{
    flex:1;
}

.fr-title{
    font:var(--font-h2b-32);
}

.fr-status{
    font:var(--font-h5b-18);
    color: var(--color-yellow-lable);
    margin:10px 0 20px;
}

.fr-status._pr{
    color: var(--color-green-lable);
}

.fr-cards{
    display:grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap:24px;
}

.fr-tags{
    display:grid;
    grid-gap:20px;
}

.fr-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}