.fr-fr{
    display:flex;
    align-items: flex-start;
    grid-gap:30px;
}

.fr-fr-save{
    position:sticky;
    top:80px;
}

.fr-fr-content{
    flex:1;
}


/* form*/
.fr-fr-form{
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:30px;
}

.fr-fr-section{
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:24px;
}

.fr-fr-section .button-small{
    margin-left:20px;
}

/* simple from */

.fr-fr-s{
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:25px;
    border:1px solid #ccc;
    padding:30px 20px;
    margin-bottom:-20px;
    border-radius:2px;
    &._two{
        grid-template-columns: repeat(2,1fr);
    }
    .fr-fr-data{
        display:grid;
        grid-gap:30px;
        grid-template-columns: repeat(2,1fr);
    }
}   

/* doc*/
.fr-fr-d{
    border:1px solid #ccc;
    border-radius:2px;
    padding: 30px 20px 40px;
    margin-bottom:-20px;
    grid-gap: 30px;
    display: grid;
    >div{
        display: grid;
        grid-template-columns: 1fr 1fr 0fr;
        grid-gap: 30px;
        align-items: center;
    }
}

.fr-fr-name{
    word-break: break-all;
}