.checkbox{
    position: relative;
}
.checkbox label {
    font:var(--font-text4-16);
    font-weight: normal;
	padding-left: 28px;
	display: inline-block;
	cursor: pointer;
}

/*.checkbox input[type="radio"] + label:before,
.checkbox input[type="radio"] + label:after {
    border-radius:50%;
}*/

.checkbox label:before {
    content:'';
	width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 2px;
    background: transparent;
    border-radius:2px;
    border: 1px solid var(--color-black-90);
}

.checkbox input:checked + label:before{
    background:url(~/public/img/checkbox/checked.svg) no-repeat 50% 50% var(--color-black-90);
}

.checkbox input{
	display: none;
}