.mail-atach{
    margin-bottom:10px;
}

.mail-atach-item{
    display:inline-flex;
    grid-gap:5px;
    padding:5px;
    padding-left:10px;
    align-items: center;
    background:var(--color-blue-50);
    border-radius:4px;
    margin:0 3px 3px 0px;
    font:var(--font-text5-14);
}