.simple-page{
    margin:30px 0px 80px;
    position:relative;
}

.simple-title{
    text-align: center;
    font:var(--font-h1b-50);
}

.simple-text{
    font:var(--font-text2-24);
    margin-top: 30px;
}

.simple-paypal{
    margin:30px 0px;
    height:60px;
    img{
        height:100%;
        width: auto;
        object-fit: cover;
    }
}