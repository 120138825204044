@import '../../css/var.scss';

.services-info-title{
    margin-bottom:20px;
}

.services-info-main{
    margin:0 auto;
    margin-top:60px;
    width:80%;
}

/*services etap*/
.services-info-line{
    display:block;
    width:6px;
    height:100%;
    position: absolute;
    z-index:1;
    top:0;
    left:42px;
    margin-left:-3px;
    background:url(./img/line.svg);
    background-size: 6px auto;
    background-repeat: repeat-y;
    background-position-x: center;
}
.services-info-etap{
    display:grid;
    grid-template-columns: 120px 1fr;
    text-align:left;
    grid-gap:30px 0px;
    margin-top:40px;
    align-items: center;
    position:relative;
}

.services-info-number{
    height:100%;
}

.services-info-title1{
    margin-bottom:16px;
}

.services-info-img{
    display:inline-block;
    height:100%;
    width:80px;
    background-repeat:no-repeat;
    background-position: 50% 50%;
    background-size:contain;
    z-index:2;
    position:relative;
}

.services-info-img span{
    content:'';
    position:absolute;
    z-index:2;
    display:block;
    top:0;
    left:0;
    background:#fff;
    width:100%;
    height:calc((100% - 80px) / 2);
}

.services-info-img span._bottom{
    top:unset;
    bottom:0;
}

.services-info-img._ico1{background-image:url(./img/1.png);}
.services-info-img._ico2{background-image:url(./img/2.png);}
.services-info-img._ico2_1{background-image:url(./img/2_1.png);}
.services-info-img._ico3{background-image:url(./img/3.png);}
.services-info-img._ico3_1{background-image:url(./img/3_1.png);}
.services-info-img._ico4{background-image:url(./img/4.png);}

.services-info-item{
    padding:30px 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

/*services info*/
.services-info-option{
    display:grid;
    grid-template-columns: 90px 1fr 90px 1fr;
    text-align:left;
    grid-gap:30px 20px;
    margin:60px 0 20px;
    @media(max-width:$pad){
        grid-template-columns: 90px 1fr;
    }
}

.services-info-ico{
    display:inline-block;
    height:70px;
    width:70px;
    background-repeat:no-repeat;
    background-position: 50% 50%;
    background-size:cover;
}

.services-info-ico._ico1{background-image:url(./img/info1.png);}
.services-info-ico._ico2{background-image:url(./img/info2.png);}
.services-info-ico._ico3{background-image:url(./img/info3.png);}
.services-info-ico._ico4{background-image:url(./img/info4.png);}
.services-info-ico._ico5{background-image:url(./img/info5.png);}
.services-info-ico._ico6{background-image:url(./img/info6.png);}