@import '../var.scss';
.cookie{
    background:none;
    position:fixed;
    z-index:1000;
    height:100%;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:40px;
    cursor: pointer;
    @media(max-width:$mobile){
        padding:0;
    }
    &.active{
        background:rgba(0,0,0,.3);
    }
}

.cookie-block{
    cursor: default;
    display:flex;
    grid-gap:30px;
    align-items: flex-start;
    padding:40px;
    background:#fff;
    transition:margin-bottom 1s,opacity 0.3s;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    overflow: auto;
    max-height: 100%;
    max-width:100%;
    margin-bottom:-100px;
    opacity:0;
    .cookie.active &{
        opacity:1;
        margin-bottom:0%;
    }
    @media(max-width:$mobile){
        flex-direction: column;
    }
}

.cookie-text{
    margin-top:20px;
    grid-gap:10px;
    display:grid;
}

.cookie-right{
    flex:0 0 auto;
}

.cookie-button{
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:20px;
}