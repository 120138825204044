@import '../../css/var.scss';
.home{
    background:#F7F8FA;
}
/*home-banner*/
.home-banner{
    display:flex;
    color:var(--color-white);
    @media(max-width:$pad){
        flex-direction: column;
    }
}

.home-banner-item{
    flex:1;
    padding:70px 80px 100px;
    background-position: center;
    background-size:auto 100%;
    background-image:url(/img/main/banner.jpg);
    position:relative;
    display:flex;
    justify-content: center;
    @media(max-width:$pad){
        padding:60px 40px;
    }
}

.home-banner-right:before,
.home-banner-left:before{
    position:absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    content: '';
    display:block;
    z-index:0;
    opacity: .9;
}

.home-banner-left:before{
    background:#0F6FFF;
}

.home-banner-right:before{
    background:#6A6C72;
}

.home-banner-content{
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width:100%;
    max-width: 800px;
}

.home-banner-title{
    font:var(--font-h1-40);
    text-align:center;
}

.home-banner-text{
    padding:10px 0px 20px;
    text-align:center;
    flex:1;
}


/* icons*/

.home-icons{
    display:grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 60px 20px;
    margin-top:40px;
    @media(max-width:$pad){
        grid-template-columns: 1fr;
    }
}

.home-icons-item{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.home-icons-img{
    height:70px;
    width: 70px;
    display:block;
    margin-bottom:16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position:center;
}

.home-icons-img._ico1{background-image:url(./img/home/ico/ico1.svg)}
.home-icons-img._ico2{background-image:url(./img/home/ico/ico2.svg)}
.home-icons-img._ico3{background-image:url(./img/home/ico/ico3.svg)}
.home-icons-img._ico4{background-image:url(./img/home/ico/ico4.svg)}
.home-icons-img._ico5{background-image:url(./img/home/ico/ico5.svg)}
.home-icons-img._ico6{background-image:url(./img/home/ico/ico6.svg)}

/* main */
.home-graf-content{
    margin:0 auto;
    display: grid;
    grid-gap: 60px;
    grid-template-columns: repeat(auto-fit,minmax(128px,1fr));
    justify-content: center;
    @media(max-width:$pad){
        flex-direction: column;
    }
}

.home-graf-item{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.home-graf-circle{
    position:relative;
    display:flex;
    align-items: center;
    justify-content: center;
    height: 128px;
    width: 128px;
    margin-bottom: 20px;
}

.home-graf-val,
.home-graf-img{
    position:absolute;
}

.home-graf-val,
.home-graf-title{
    color:#fff;
    font-family: moserrat;
    font-size:20px;
}

/* main logo*/
.home-company{
    //height:450px;
    // width:100%;
    // max-width: 1070px;
    // grid-gap:30px;
    // display:grid;
    // justify-content: flex-start;
    // grid-template-columns: repeat(auto-fill,minmax(100px,1fr));
    // padding:0px 30px;
    // @media(max-width:1070px){
    //     max-width: 810px;
    // }
    // @media(max-width:810px){
    //     max-width: 550px;
    // }
    // @media(max-width:550px){
    //     max-width: 420px;
    // }
}

.home-company-item{
    height:120px;
    width: 120px;
    padding:10px;
    overflow: hidden;
    background:var(--color-white);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex:0 0 120px;
    cursor:pointer;
}

.home-company-item._shadow{
    box-shadow: 0px 10px 10px #2a3e7233;
    border-radius: 4px; 
}

.home-company-item img{
    object-fit: contain;
    width:100%;
    height:100%;
    filter: grayscale(1);
    transition: 0.3s;
    &:hover{
        transform: scale(1.2);
        filter: grayscale(0);
    }
}

/* main home-freelancer*/
.home-freelancer-container{
    color:#000;
    cursor:pointer;
}

.home-freelancer-item{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.home-freelancer-foto{
    border-radius:100%;
    height:100px;
    width:100px;
    box-shadow:0px 20px 30px rgba(42, 62, 114, 0.2);
    overflow:hidden;
    position:relative;
}

.home-freelancer-foto img{
    object-fit: cover;
    width:100%;
    height:100%;
    // .active &{
    //     display:none;
    // }
}

.home-freelancer-status{
    position: absolute;
    background:rgba(255, 255, 255, 0.7);
    height: 100%;
    width: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    display:none;
    .active &{
        display:flex;
    }

}

.home-freelancer-name{
    margin-top:16px;
    font:var(--font-h5b-18)
}

.home-freelancer-job{
    font:var(--font-text4-16);
    margin-top:5px;
    opacity:.5;
}

/*feedback*/
.home-cart{
    margin:40px auto;
}

.home-cart-item{
    background:var(--color-white);
    box-shadow: 0px 20px 30px rgba(42, 62, 114, 0.2);
    border-radius: 4px;
    display:inline-block;
    margin:0 auto;
    padding:30px;
    width:100%;
    max-width:400px;
}
.home-cart-text{
    margin-top:20px;
}

// top proj

.home-proj-item{
    display:flex;
    flex-direction: column;
    height:100%;
    text-align: left;
    padding:16px;
    color:#000;
    background: #FFFFFF;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
    cursor:pointer;
}

.home-proj-title{
    flex:1 1 75px;
    font:var(--font-h7b-14);
    text-overflow: ellipsis;
    word-break: break-word;
    overflow: hidden;
}

.home-proj-icons{
    margin-top:20px;
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap:10px;
    font:var(--font-text6-13);
    color:var(--color-black-50);
}

.home-proj-icons>div{
    display:flex;
    align-items: center;
}

.home-proj-icons .icons{
    margin-right:5px;
    height:24px;
    width:24px;
    opacity:0.5;
}

// home news
.home-news-slider{
    margin:20px auto;
}
.home-news{
    margin-top:40px;
    margin-bottom:-40px;
}

.home-news-icons{
    display:flex;
    grid-gap:30px;
    justify-content: flex-start;
    padding:20px 20px 0px;
    .icons._like.active{
        filter:grayscale(0);
    }
}

.home-news-data{
    color: var(--color-dark-400);
    font-weight: 600;
    font-size: 12px;
    margin-top: 10px;
}

.home-news-item{
    width: 100%;
    display: flex;
    height:100%;
    flex-direction: column;
    background:#fff;
    box-shadow:0 0 5px rgba(0,0,0,.1);
    border-radius:5px;
    padding-bottom: 30px;
}

.home-news-descr{
    padding:20px;
    padding-bottom:0px;
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.home-news-title{
    display:block;
    margin:10px 0px 20px;
}

.home-news-img{
    border-radius:5px 5px 0px 0px;
    height:200px;
    overflow: hidden;
    img{
        object-fit: cover;
        height:100%;
        width: 100%;
    }
}