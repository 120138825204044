@import '../var.scss';
/*! */
.pr{
    z-index: 1;
    position:relative;
    padding:40px 0 30px;
}

.pr:before{
    content: '';
    top:0;
    left:0;
    right:0;
    bottom:0;
    position:absolute;
    z-index: -2;
    background:url('/img/det/background.jpg') no-repeat 50% 50%;
    background-size: cover;
    opacity:0.2;
}
.pr:after{
    content: '';
    top:0;
    left:0;
    right:0;
    bottom:0;
    position:absolute;
    z-index: -1;
    background: linear-gradient(0deg, transparent, #ffffff99);
}

.pr-grid{
    display:flex;
    align-items: flex-start;
    grid-gap:20px;
    @media(max-width:$pad){
        flex-direction: column;
        align-items: center;
    }
}

.pr-top{
    display:flex;
    align-items:center;
    justify-content:space-between;
    grid-gap:30px;
    @media(max-width:$pad){
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}
.pr-sub{
    display:flex;
    grid-gap:20px;
    margin-bottom:30px;
    align-items: center;
    @media(max-width:$pad){
        align-items: flex-start;
        flex-direction: column;
    }
}

.pr-data{
    align-items: center;
    grid-gap:10px;
    display:flex;
}

.pr-number{
    color:var(--color-black-50);
    font:var(--font-text3-18);
}

.pr-title{
    font:var(--font-h2b-32);
}

.pr-info{
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top:20px;
    grid-gap: 40px;
    @media(max-width:$pad){
        flex-direction: column;
        align-items: stretch;
    }
}
.pr-right{
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    grid-gap:20px;
    @media(max-width:$pad){
        align-items:stretch; 
        width: 100%;
    }
}
.pr-right-icons{
    display:flex;
    grid-gap: 10px;
    @media(max-width:$pad){
        justify-content:center; 
        width: 100%;
    }
}

.pr-right-buttons{
    display:flex;
    justify-content: flex-end;
    flex-direction: column;
    grid-gap:10px;
    align-items: flex-end;
    @media(max-width:$pad){
        justify-content:center;
        flex-direction: row;
        width: 100%;
    }
    @media(max-width:$mobile){
        flex-direction: column;
        .button{
            width: 100%;
        }
    }
}

/* icons*/
.pr-info-icons{
    display:grid;
    grid-template-columns: repeat(2,250px);
    grid-gap:10px 20px;
    @media(max-width:$mobile){
        grid-template-columns: 1fr;
    }
}

.pr-icons{
    display:grid;
    grid-template-columns: repeat(2,250px);
    grid-gap:10px 20px;
    // a{
    //     color:#222222;
    // }
    @media(max-width:$mobile){
        grid-template-columns: 1fr;
    }
}

.pr-icons._ver{
    grid-template-columns: 1fr;
}

.pr-item{
    display:flex;
    align-items: flex-start;
    font-weight: 600;
    .icons{
        margin-right:10px;
        flex: 0 0 auto;
    }
}

.pr-item-text{
    font:var(--font-text4-16);
    padding-top:5px;
}

// Freelance Det
.pr-free{
    font:var(--font-text4-16);
    display:flex;
    align-items: flex-start;
    grid-gap:30px;
    @media (max-width:$mobile) {
        flex-direction: column;
    }
}
.pr-feee-top{
    display:flex;
    align-items: center;
    grid-gap:10px;
    margin-bottom:10px;
}