.mes-mail{
    width: 100%;
    max-width:300px;
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:20px;
}

.mes-mail-atach{
    display: flex;
    align-items: center;
    justify-content: center;
    position:absolute;
    bottom:0;
    right:0;
    height:32px;
    width:32px;
}