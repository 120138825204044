@import '../../css/var.scss';

/*menu-right*/
.page-menu-admin{
    background:#000;
    padding:10px;
    z-index: 101;
    position: relative;
    color:#fff;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    cursor:pointer;
    // &:hover{
    //     background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
    // }
}
.page-menu-block{
    top:0;
    position:sticky;
    z-index: 100;
}
.page-menu{
    display:flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(34, 34, 34, 0.2);
    height: 80px;
}

.page-menu-center{
    flex:1;
    align-self: stretch;
}

.page-menu-list{
    display:flex;
    justify-content: center;
    align-items: stretch;
    height: 100%;
    @media (max-width:$pad){
        display:none;
        position: absolute;
        top: 100%;
        height: auto;
        background: #fff;
        flex-direction: column;
        left: 0;
        width: 100%;
        box-shadow: 0 5px 10px rgba(0,0,0,.1);
        &.active{
            display:flex;
        }
    }
}

.page-menu-ico{
    display:none;
    @media (max-width:$pad){
        display:block;
    }
}

.page-menu-item{
    position:relative;
    cursor:pointer;
}

.page-menu-link{
    height: 100%;
    display:flex;
    align-items: center;
    padding:15px;
    color:#222;
    >a{
        color:#222;
        &:hover{
            color:var(--color-blue-500);
        }
    }
    font:600 16px moserrat;
    .page-menu-item:hover &{
        color:var(--color-blue-500);
        background: var(--color-black-10);
    }
    &._has-menu{
        padding-right:30px;
        position:relative;
        &:after{
            content: '';
            display:block;
            position: absolute;
            right:5px;
            top:50%;
            transform: translateY(-50%);
            background:url(/img/main/menu_arrow.svg) no-repeat 50% 50%;
            background-size: contain;
            height:100%;
            width: 20px;
        }
    }
}

// dropdown
.page-menu-dropdown{
    top:100%;
    left:50%;
    transform: translateX(-50%);
    display:none;
    position:absolute;
    padding-top:10px;
    z-index:10000;
    &.active{
        display:block;
    }
    @media (max-width:$pad){
        position:relative;
        width: 100%;
        padding-top: 0px;
        top:unset;
        left:unset;
        transform: none;
    }
}

.page-menu-block{
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(34, 34, 34, 0.1);
    max-height:200px;
    min-width:250px;
    &:after{
        content:'';
        position:absolute;
        z-index:1;
        top:2px;
        transform: rotate(-45deg);
        background: #fff;
        height:16px;
        width:16px;
        left:50%;
        margin-left:-8px;
        display:block;
        z-index:-1;
    }
    @media (max-width:$pad){
        box-shadow: none;
        &:after{display:none};
        max-height: unset;
    }
}

.page-menu-cat{
    display:block;
    padding:8px 16px;
    font:var(--font-text3-18);
    color:var(--color-blue-500);
    @media (max-width:$pad){
        display:inline-block;
    }
    &:hover{
        background:var(--color-black-10);
    }
}