/* import  fonts*/
@font-face{
    font-family:moserrat;
    font-weight:400;
    font-style:normal;
    src:url(fonts/Montserrat/Montserrat-Light.ttf);
    font-display: swap;
}

@font-face{
    font-family:moserrat;
    font-weight:600;
    font-style:normal;
    src:url(fonts/Montserrat/Montserrat-Bold.ttf);
    font-display: swap;
}


@font-face{
    font-family:open_sans;
    font-style:normal;
    font-weight:400;
    src:url(fonts/Open_Sans/OpenSans-Regular.ttf);
    font-display: swap;
}

@font-face{
    font-family:open_sans;
    font-style:normal;
    font-weight:600;
    src:url(fonts/Open_Sans/OpenSans-Bold.ttf);
    font-display: swap;
}

//?BOLD BUTTON

:root{
    --font-button1-16:600 14px moserrat; //+
    --font-button2-13:600 14px moserrat;
}

.font-button1-16{
    text-transform: uppercase;
    font:var(--font-button1-16);
}

.font-button2-13{
    text-transform: uppercase;
    font:var(--font-button2-13);
}

//? BOLD
:root{
    --font-h1b-50:700 32px moserrat;
    --font-h1b-40:700 26px moserrat;
    --font-h2b-32:700 22px moserrat;
    --font-h3b-24:700 16px moserrat;
    --font-h4b-20:700 14px moserrat;
    --font-h5b-18:700 14px moserrat;
    --font-h6b-16:700 13px moserrat;
    --font-h7b-14:700 13px moserrat;
}

.font-h1b-50{font:var(--font-h1b-50);}
.font-h1b-40{font:var(--font-h1b-40);}
.font-h2b-32{font:var(--font-h2b-32);}
.font-h3b-24{font:var(--font-h3b-24);}
.font-h5b-18{font:var(--font-h5b-18);}
.font-h6b-16{font:var(--font-h6b-16);}
.font-h7b-14{font:var(--font-h7b-14);}

//? REGULAR

:root{
    --font-h1-40:32px moserrat;
    --font-h2-32:22px moserrat;
    --font-h2-24:16px moserrat; 
    --font-h3-18:14px moserrat;
    --font-h4-14:12px moserrat;
}

.font-h1-32{font:var(--font-h2-32);}
.font-h2-24{font:var(--font-h2-24);}
.font-h3-18{font:var(--font-h3-18);}
.font-h4-14{font:var(--font-h4-14);}

//? REGULAR OPEN SANS

:root{
    --font-text1-32:22px open_sans;
    --font-text2-24:16px open_sans;
    --font-text3-18:14px open_sans;
    --font-text4-16:13px open_sans;
    --font-text5-14:12px open_sans; // smollest
    --font-text6-13:10px open_sans;
}

.font-text1-32{font:var(--font-text1-32)}
.font-text2-24{font:var(--font-text2-24)}
.font-text3-20{font:var(--font-text3-20)}
.font-text3-18{font:var(--font-text3-18)}
.font-text4-16{font:var(--font-text4-16)}
.font-text5-14{font:var(--font-text5-14)}
.font-text6-13{font:var(--font-text6-13)}


._font-white{
    color:#fff;
}