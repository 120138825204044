
@import '/src/var.scss';

.login{
    background-size:cover;
    display:flex;
    align-items: center;
    justify-content: center;
    padding:40px 80px;
    min-height: 100%;
    @media(max-width:$pad){
        padding:40px;
    }
    @media(max-width:$mobile){
        padding:20px;
    }
}

.login .icons._menu{
    background-color: #fff;
}

.login-overflow{
    position: fixed;
    z-index:10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background:url(/img/modal/bg.jpg) no-repeat 50% 50% var(--color-dark-500);
    background-size:cover;
    >:before{
        content: '';
        z-index: -1;
        top: 0;
        left: 0;
        bottom: 0;
        position: fixed;
        background: var(--color-dark-500);
        opacity: 0.7;
        right: 0;
    }
}

.login-title._center{
    text-align:center;
}
.login .button{
    width:100%;
}

.login-block{
    cursor:default;
    z-index:1;
    background:#fff;
    border-radius:15px;
    padding:40px 40px 60px;
    width:100%;
    max-width:800px;
}

/* inluce*/
.login-content{
    display:grid;
    align-items: center;
    grid-template-columns: 1fr 2fr;
    grid-gap:60px;
    @media(max-width:$pad){
        grid-gap:40px;
        grid-template-columns: 1fr;
    }
}

.login-main{
    margin-left:60px;
    @media(max-width:$pad){
        margin-left:unset;
    }
}

.login-left{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height:100%;
    text-align: center;
}

.login-logo{
    width:100%;
    height:100px;
}

.login-logo img{
    width:100%;
    height: 100%;
    object-fit: contain;
}

.login-text{
    font:var(--font-h3b-24);
    color:var(--color-dark-500);
    margin-top:20px
}

.login-small-text{
    font:var(--font-text4-16);
    margin-bottom:10px;
}

.login-soc{
    margin-bottom: 10px;
}

/*form*/
.login-form{
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:20px;
}

.login-form-flex{
    display:flex;
    flex-wrap: wrap;
    grid-gap:30px;
    margin-right: -30px;
    @media(max-width:$pad){
        margin-right: 0px;
    }
}

.login-form-flex>div{
    flex:1 1 auto;
    width: 0;
    @media(max-width:$pad){
        flex:auto;
        width:100%;
    }
}

.login-form-flex:after{
    content:'';
    display:block;
    flex:0;
}

.login-form-stat{
    display:grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    grid-gap:20px;
    @media(max-width:$pad){
        grid-template-columns: 1fr
    }
}

.login-pas-text{
    margin-top:10px;
    font:var(--font-text6-13);
}

.login-center{
    display:flex;
    align-items: center;
    margin-bottom:30px;
}
.login-center span{
    margin:0 10px;
    flex:0 0 auto;
}

.login-center:after,
.login-center:before{
    display:block;
    content:'';
    height:1px;
    background:#999;
    flex:1
}

.login-flex{
    display: flex;
    align-items: flex-end;
    justify-content: space-between; 
}

.login-buttons{
    display:flex;
    justify-content: flex-end;
    grid-gap:10px;
}