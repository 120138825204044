.news{
    margin:60px 0px 120px;
}

.news-list{
    display:grid;
    grid-gap:30px;
    margin:40px 0px;
}

.news-title{
    margin-bottom:20px;
}

.news-data{
    color:var(--color-dark-400);
    font-weight: 600;
    font-size: 14px;
    margin-top:10px;
}

.news-det-href{
    text-align: right;
    font-size:12px;
}