// search
.table-search{
    display:flex;
    justify-content:stretch;
    grid-gap:10px;
    align-items: center;
    // border:2px solid #ccc;
    >div{
        line-height: 1;
        &:last-child{
            flex:1;   
            display:flex;
            justify-content: flex-end;
        }
    }
}

/* table*/
.table{
    width: 100%;
    position:relative;
    align-items: center;
    border-collapse: collapse;
    >tbody{
        position:relative;
    }
    td{
        font:var(--font-text5-14);
        align-self: flex-end;
    }
}

.table-title{
    >td{
        padding:16px;
        padding-left:0;
        border-bottom:1px solid;
        vertical-align: middle;
        font:var(--font-h6b-16);
    }
}

.table-flex{
    display:flex;
    align-items: center;
    grid-gap:5px;
}

.table-row{
    >td{
        padding:16px 10px;
        padding-left:0;
        vertical-align: middle;
        //font:var(--font-text4-16);
        border-bottom:1px solid var(--color-black-10);
        &:first-child{
            min-width:200px;
        }
    }
    ._link{
        cursor:pointer;
    }
    &:hover>td{
        border-bottom:1px solid var(--color-black-30);
    }
    &:last-child:hover>td,
    &:last-child>td{
        border:none;
    }
    &._bottom>td{
        vertical-align: center;
    }
}

.table-icons{
    display:flex;
    grid-gap:5px;
    align-items: center;
    justify-content: flex-end;
}

.table-image{
    display:flex;
    grid-gap:20px;
    align-items: center;
    color:#000;
}

.table-image-ico{
    height:40px;
    flex:0 0 40px;
    overflow: hidden;
    border-radius:100%;
    background:#fff;
    display:block;
    box-shadow: 3px 3px 5px rgba(0,0,0,.1);
    &._com{
        border-radius:3px;
    }
    >img,
    >.icons{
        border-radius:100%;
        object-fit: cover;
        height:100%;
        width:100%;
    }
}

.table-image-user{
    height:100%;
    width: 100%;
    -webkit-mask-image:url(/img/icons/user.svg);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 70%;
    -webkit-mask-position: 50% 50%;
    background-color:#ccc;
}

.table-data{
    span{
        opacity:0.5;
    }
}

.table-bold{
    font:var(--font-h5b-18);
}

.table-status{
    margin-left: 10px;
    display: flex;
    grid-gap: 10px;
    align-items: center;
}