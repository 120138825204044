.navi{
    font:var(--font-h7b-14);
    display: flex;
    flex-wrap: wrap;
    grid-gap:10px;
}

.navi-item{
    color:var(--color-blue-500);
    .navi-block:last-child &{
        cursor:auto;
        color:var(--color-black);
        pointer-events: none;
    }
    ._white .navi-block:last-child &{
        color:var(--color-white);
    }
}

.navi-block{
    display:flex;
    grid-gap:10px;
}

.navi-line{
    .navi-block:first-child &{
        display:none;
    }
    @media(max-width:600px){
        display: none;
    }
}