// position
.modal{
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor:pointer;
}

// all view
.modal-block{
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    transition:0.3s;
    opacity:0;
    height:100%;
    width:100%;
    .modal.active &{
        opacity:1;
    }
    &:before{
        content: "";
        z-index: -1;
        top: 0;
        left: 0;
        bottom: 0;
        position: fixed;
        background: var(--color-dark-500);
        opacity: 0.7;
        right: 0;
    }
}

// inside
.modal-content{
    cursor: default;
    background: #fff;
    position: relative;
    z-index: 1;
    max-width: 600px;
    margin: 30px;
    max-height: calc(100% - 60px);
    padding: 30px;
    border-radius: 4px;
    @media(max-width:768px){
        max-height: 100%;
        margin:0px;
    }
    
}

// close button
.modal-close{
    cursor:pointer;
    background:url(/img/icons/close.svg) no-repeat 50% 50%;
    background-size:16px 16px;
    height:40px;
    width:40px;
    position:absolute;
    top:0;
    right:0;
}