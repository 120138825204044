.rat{
    display:flex;
    align-items: center;
    grid-gap:5px;
    line-height: 1;
    margin:10px 0px;
    pointer-events: none;
}

.rat-points{
    font-size: 14px;
    font-weight: 600;
    color:var(--color-blue-500);
}