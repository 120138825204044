.logo{
    height:160px;
    width:160px;
    background: #FFFFFF;
    box-shadow: 0px 20px 30px rgba(42, 62, 114, 0.2);
    border-radius: 4px;
    // padding:28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo._2d{
    box-shadow: none;
}

.logo img{
    height:100%;
    width:100%;
    object-fit:contain;
}

.foto{
    height:140px;
    width:140px;
    background: #FFFFFF;
    box-shadow: 0px 20px 30px rgba(42, 62, 114, 0.2);
    border-radius: 100%;
    overflow:hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.foto._2d{
    box-shadow: none;
}

.foto img{
    height:100%;
    width:100%;
    object-fit:contain;
}