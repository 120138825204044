@import '../../var.scss';

.other-det{
    padding-bottom:60px;
    z-index: 1;
}
.other-det-pr{
    display:grid;
    grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
    grid-gap:24px;
}

.other-det-pritem{
    padding:16px;
    display: flex;
    flex-direction: column;
    color:#000;
    background: #FFFFFF;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
    cursor:pointer;
}

.other-det-prtitle{
    flex:1;
    font:var(--font-h7b-14);
}

.other-det-pricons{
    margin-top:20px;
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap:10px;
    font:var(--font-text6-13);
    color:var(--color-black-50);
}

.other-det-pricons>div{
    display:flex;
    align-items: center;
}

.other-det-pricons .icons{
    margin-right:5px;
    height:24px;
    width:24px;
    opacity:0.5;
}

.other-det-button{
    display:flex;
    justify-content: center;
    padding:40px 0px;
}

/* contacts form*/
.other-det-contacts{
    display:flex;
    grid-gap:40px;
    align-items: flex-start;
    @media(max-width:$pad){
        flex-direction: column;
    }
}

.other-det-form,
.other-det-map{
    flex:1;
    width:100%;
}

.other-det-map>img{
    height:100%;
    width:100%;
    object-fit:contain;
}

/* list*/
.other-det-ls{
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:24px;
}
.other-det-lstext{
    font:var(--font-text3-18);
    color:var(--color-black-50);
}

.other-det-lssub{
    font:var(--font-text3-18);
    color:var(--color-black-90);
    margin:5px 0px;
}

.other-det-lstitle{
    font:var(--font-h3b-24);
    margin-bottom:10px;
    >span{
        margin-left:15px;
    }
}

/* grid*/
.other-det-gr{
    margin:10px 0;
    display:table;
    justify-content: flex-start;
    grid-template-columns: repeat(2,minmax(100px,1fr));
    grid-gap:10px;
}

.other-det-gritem{
    display:table-row;
    >div{
        display:table-cell;
        padding:5px 10px 5px 0px;
    }
}

.other-det-grtitle{
    font:var(--font-h4b-20);
}

/* doc */
.other-det-doc{
    display:flex;
    grid-gap:30px;
}

.other-det-dcitem{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.other-det-dcitem:before{
    content:'';
    height:80px;
    width:80px;
    background:url(/img/page/other/doc.svg) no-repeat 50% 50%;
}

.other-det-dctext{
    font:var(--font-text4-16);
    margin-top:10px;
    color:#000;
}

.other-det-button._left{
    justify-content: flex-start;
}

// soc
.other-det-soc{
    display:flex;
    grid-gap:10px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.other-det-soc a{
    filter: grayscale(1) brightness(1);
    transition: 0.2s;
    &:hover{
        filter: brightness(0);
        filter: grayscale(0);
    }
}

// Proj Det

.other-det-company{
    display:flex;
    align-items: center;
    margin-top:20px;
    grid-gap:60px;
    .logo{
        flex:0 0 160px;
    }
    @media (max-width:$pad) {
        grid-gap:30px;
        flex-direction: column;
    }
}

.other-det-info{
    display:flex;
    grid-gap:10px;
    flex-direction: column;
    @media (max-width:$pad) {
        align-items: center;
    }
}

.other-det-icons{
    display:grid;
    grid-template-columns: repeat(2,250px);
    grid-gap:10px 60px;
    @media (max-width:$mobile) {
        grid-template-columns:1fr;
    }
}

.other-det-item{
    display:flex;
    align-items:center;
}

.other-det-item .icons{
    margin-right:10px;
}

// contact person
.other-det-contact{
    display:flex;
    flex-wrap: wrap;
    grid-gap:15px;
    align-items: center;
    >div{
        display:flex;
        align-items: center;
        grid-gap:5px;
    }
}