@import '../var.scss';

.card{
    cursor: pointer;
    height: 100%;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(26, 54, 98, 0.2);
    border-radius: 4px;
    text-align: center;
    padding:20px 10px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.card .icons{
    background-color: var(--color-black-30);
}

.card .card-ico{
    display: flex;
    align-items: center;
    justify-content: center;
    flex:0 0 56px;
}
 
.card:hover .icons{
    background-color: var(--color-blue-500);
}

.card-title{
    color: var(--color-blue-500);
    font:var(--font-button1-16);
    text-transform: uppercase;
}

.card-text{
    color: var(--color-black-50);
    font:var(--font-text6-13);
}