.RichEditor-root {
    position:relative;
    padding-bottom: 50px;
}

.RichEditor-toolbar{
    user-select: none;
    position:absolute;
    bottom:10px;
    left:10px;
    display:flex;
    grid-gap:10px;
    z-index: 1;
}
  
.RichEditor-editor{
    cursor: text;
}


.RichEditor-styleButton {
  user-select: none;
  cursor: pointer;
  display: inline-flex;
  height:24px;
  width:24px;
  background:#fff;
  box-shadow: 0px 3px 4px rgba(23, 28, 75, 0.2);
  border-radius: 2px;
  &:hover{
    background-color:var(--color-black-10);
    box-shadow: none
  }
}

.RichEditor-activeButton {
  color: #5890ff;
}

.DraftEditor-root{
  overflow: auto;
  min-height: 100px;
  max-height: 300px;
  padding: 10px 10px 0px 10px;
  font: var(--font-text3-18);
}

.DraftEditor-editorContainer{
    position: relative;
    z-index: 1;
}

.public-DraftEditorPlaceholder-root{
  color: #9197a3;
  position: absolute;
  width: auto !important;
  z-index: 1;
}

.public-DraftEditorPlaceholder-hasFocus{
  color: #bdc1c9;
}