@keyframes circle-animation {
  to {
      stroke-dasharray:540 0;
  }
}

.circle {
  .animated &{
    animation-duration: 1500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-name: circle-animation;
  }
}