@import '../css/var.scss';

/* main page*/
.page-top{
    background:var(--color-black-70);
    padding:10px;
    display:flex;
}

.page-top-left{
    display:flex;
    grid-gap:20px;
    flex:0 0 220px;
    align-items: center;
    padding:8px 10px;
}

.page-top-right{
    padding:0px 15px;
}

.page-top-logo{
    height:45px;
    display:block;
    padding:7px;
}

.page-top-logo img{
    height:100%;
    width:auto;
    object-fit: contain;
}

.page-top-contacts{
    color:var(--color-white);
    padding:0px 50px 0px 20px;
    position:relative;
    text-decoration: none;
    display: flex;
    align-items: center;
    height:16px;
    @media(max-width:$pad){
        padding:0;
        width:40px;
    }
    >span{
        @media(max-width:$pad){
            display:none;
        }
    }
}

.page-top-contacts:before{
    content: '';
    display:block;
    position: absolute;
    left:0;
    background-size:contain;
    height: 14px;
    width: 14px;
}

.page-top-contacts._email:before{
    background-image:url(/img/main/email.svg);
}

.page-top-contacts._phone:before{
    background-image:url(/img/main/phone.svg);
}