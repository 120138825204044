.taglist{
    display:flex;
    flex-wrap: wrap;
    grid-gap:8px;
    >.taglist-item{
        background:var(--color-black-10);
        padding:8px;
        border-radius:3px;
        font:var(--font-text5-14);
        color:var(--color-black-90);
        display:flex;
        align-items: center;
        >.taglist-item-del{
            height:16px;
            width:16px;
            flex:0 0 16px;
            margin-left:10px;
            filter:grayscale(1);
            &:hover{
                filter:grayscale(0);
            }
        }
    }
    
}