.section{
    .section-content &{
        font:var(--font-h6b-16);
        display:flex;
        justify-content: space-between;
        grid-gap:10px;
        align-items: center;
        background: var(--color-blue-50);
        padding:12px;
        padding-left:24px;
        position:relative;
        cursor:pointer;
    }
    .section-content.active &{
        background: var(--color-black-5);
    }
}
// block
.section-block{
    .section-content &{
        display:none;
        margin-top:24px;   
    }
    .section-content.active &{
        display:block;
    }
}

// icons
.section .icons{
    .section-content.active &{
       transform: rotate(-180deg);
    }
}
 
.select__multi-value{
    border-radius: 10px !important;
    background-color: var(--color-blue-50) !important;
}

.select__multi-value__remove{
    &:hover{
        color:#0084ff !important;
        background: var(--color-blue-50) !important;
    }

}

.select__multi-value__label{
    padding:5px 0px 5px 10px !important;
    font:var(--font-text3-20);
    font-size: unset !important;
}

.select__multi-value__remove{
    padding:5px 10px !important
}