.notfound{
    font-size:100px;
    height:100%;
    width:100%;
    display:flex;
    align-items:center;
    flex-direction: column;
    justify-content: center;
    font-weight:bold;
    color:var(--color-blue-500);
    >span{
        display:block;
        color:#000;
        font-size:40px;
    }
}