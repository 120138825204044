.prof-mes{
    display:grid;
    grid-gap:20px;
    font:var(--font-text5-14);
    text-align:center;
    max-width: 350px;
}

.prof-mes-icon{
    display:flex;
    justify-content: center;
    align-items: center;
}