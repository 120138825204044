.mes-pay{
    width: 100%;
    max-width:300px;
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:30px;
}

.mes-pay-account{
    display:grid;
    grid-gap:20px;
    grid-template-columns: 1fr 1fr;
    >div{
        display:flex;
        align-items: center;
        width:100%;
        padding:10px;
        justify-content: center;
        cursor:pointer;
    }
}