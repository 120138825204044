.pag{
    display: flex;
    height:36px;
    align-self: stretch;
    justify-content: center;
}

.pag-dot{
    min-width:36px;
    padding:5px;
    display:flex;
    align-items: center;
    justify-content: center;
    color:var(--color-blue-500);
    margin:0 3px;
}

.pag-item{
    border:1px solid var(--color-blue-500);
    border-radius:2px;
    min-width:36px;
    padding:5px;
    display:flex;
    align-items: center;
    justify-content: center;
    color:var(--color-blue-500);
    margin:0 3px;
    cursor:pointer;
    &.active,
    &:hover{
        background:var(--color-dark-500);
        border-color:var(--color-dark-500);
        color:#fff;
    }
}

.pag-do{
    height:36px;
    padding:5px;
    color:var(--color-black-30);
    text-transform: uppercase;
    margin:0px 4px;
    display:flex;
    align-items:center;
    cursor:pointer;
    &.active,
    &:hover{
        color:var(--color-blue-500);
    }
    &.disabled{
        pointer-events: none;
    }
}