/**/
input,
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 

textarea,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
input:-webkit-autofill:active,

select,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    box-shadow: none !important;
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    width: 100%;
    resize: none;
    position:relative;
    background: transparent;
    border-radius:0;
}


input[type=file]{
    display:none;
}

textarea,
.textarea{
    min-height: 100px;
    padding:10px;
}


::-webkit-input-placeholder {
    color:#808080;
    font:var(--font-text3-18);
}

/* inputs*/
.inputs{
    padding:12px 14px;
    width:100%;
    font:var(--font-text3-18);
}

/* error */
.inputs-block._error,
.inputs._error{
    border-color:#db222a !important;
    >label{
        color:#db222a !important;
    }
}

.inputs-error{
    margin-top:3px;
    color:#db222a;
    font-size:12px;
}

/* inputs line*/
.inputs._line{
    border:1px solid var(--color-black-50);
    border-radius:2px;
    position:relative;
    padding:12px 14px;
}

/*input black*/
.inputs._black{
    background:#202531;
    border-radius:4px;
    border:1px solid #425179;
    padding:6px 10px;
    color:#fff;
    width: auto;
    &::-webkit-input-placeholder{
        color:#fff;
        opacity:.3;
        font-family: open_sans;
    }
}

// false textarea
.false-textarea{
    min-height: 160px;
    padding:10px 15px 10px 15px;
}

.false-textarea[placeholder]:empty:before {
    content: attr(placeholder);
    color: #808080; 
}

/*input label*/
.inputs-block._label{
    border:1px solid var(--color-black-50);
    border-radius:2px;
    position:relative;
}

.inputs-block._select{
    border:none;
}

.inputs-block._label input,
.inputs-block._label textarea{
    padding:8px 10px 9px;
    &._icon{
        padding-right:40px;
    }
}

.inputs-block._label label{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    font-size: 12px;
    top: -10px;
    left: 5px;
    max-width: 90%;
    background: #fff;
    padding: 0px 5px;
    color: var(--color-black-50);
    z-index:1;
}

.select_menu{
    z-index:100;
}

/*inputs ico*/
.inputs-ico{
    position:absolute;
    top:0;
    right:10px;
    height:100% !important;
}