@import "../../var.scss";
.fr-st-top{
    padding:20px;
    display:grid;
    grid-template-columns: 2fr 1fr;
    grid-gap:30px;
    background: #FFFFFF;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    @media(max-width:$pad){
        grid-template-columns: 1fr;
    }
}

.fr-st-right{
    text-align:center;
}

.fr-st-ico{
    display:inline-flex;
    align-items: center;
}

.fr-st-text{
    font:var(--font-text4-16);
    color:var(--color-black-50);
    margin:16px 0px
}

/*carsd*/

.fr-st-cards{
    display:grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap:30px;
    margin:24px 0px;
}

.fr-st-title{
    margin:24px 0px;
}