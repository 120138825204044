@import '/src/var.scss';
.fr-cf{
    display:flex;
    align-items: flex-start;
    grid-gap:30px;
    @media(max-width:$pad){
        flex-direction: column;
    }
}

.fr-cf-save{
    position:sticky;
    top:30px;
}

.fr-cf-content{
    flex:1;
    @media(max-width:$pad){
        width: 100%;
    }
}

/* form*/
.fr-cf-form{
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:24px;
}