.card-item{
    display:contents;
    cursor:pointer;
    color:#000;
    font:var(--font-h3-18);
}

.card-item>div{
    padding:16px;
    display:flex;
    align-items: center;
    height:100%;
    border-bottom:var(--color-black-5);
}

.card-item:hover>div{
    background:var(--color-black-5);
}

.card-item-title{
    display:flex;
    align-items: center;
    grid-gap: 20px;
}

.card-item-icon{
    display:block;
    height:64px;
    width:0 0 64px;
    border-radius:3px;
    overflow: hidden;
}

.card-item-icon img{
    height:100%;
    width:100%;
    object-fit:contain;
}

.card-item-foto{
    display:block;
    height:40px;
    flex:0 0 40px;
    border-radius:100%;
    overflow: hidden;
    display:flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 5px rgba(0,0,0,.1);
}

.card-item-foto img{
    height:100%;
    width:100%;
    object-fit:contain;
}

.card-item-name{
    font:var(--font-h7b-14);
}

/* icons*/
.card-icons{
    display:flex;
    grid-gap:10px;
    align-items: center;
}

/* icons*/
.card-map{
    display:flex;
    grid-gap:10px;
    align-items: center;
}

/* top*/
.card-top{
    position: absolute;
    height:100%;
    width:100%;
    background:var(--color-blue-50);
    padding:20px;
    display:flex;
    grid-gap:20px;
    justify-content: center;
    align-items: center;
    grid-area: 2 / 1 / 2 / auto;
}

.card-top-icons{
    height:54px;
    width:54px;
    border-radius:3px;
    overflow: hidden;
    border:1px solid var(--color-blue-light);
    background:#fff;
}

.card-top-icons img{
    object-fit: contain;
    height:100%;
    width:100%;
}

.card-top-text{
    font:var(--font-h2-24);
}