/* modal*/
.react-datepicker-popper{
    z-index: 100;
    inset: 0px auto auto -1px !important;
}

.react-datepicker-popper[data-placement^=bottom]
.react-datepicker__triangle::after{
    border-bottom-color: #fff;
}

.react-datepicker-popper[data-placement^=bottom]
.react-datepicker__triangle::before{
    border-bottom-color: #000;
}

.react-datepicker{
    border-radius:0;
    border-color:#000;
    font:var(--font-text3-18);
}

.react-datepicker__day--selected{
    border-radius: 3px;
    background-color: var(--color-blue-500);
    &:hover{
        background-color: var(--color-blue-500);
    }
}

.react-datepicker__header{
    background-color:#fff;
    border-radius: 0;
}

.react-datepicker__day{
    width: 32px;
    padding: 2px;
    &:hover{
        border-radius:3px;
    }
}