.news-det{
    margin:60px 0px 120px;
}

.news-det-title{
    margin:20px 0px 40px;
}
.news-det-data{
    color:var(--color-dark-400);
    font-weight: 600;
    font-size: 14px;
    margin-top:10px;
}

.news-det-img{
    margin-bottom:40px;
    overflow: hidden;
}

.news-det-img img{
    object-fit: contain;
    height: auto;
    width: 100%;
}

.news-det-text{
    font:var(--font-text2-24);
    line-height:2;
}