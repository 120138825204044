
.__react_component_tooltip.type-dark{
    background-color: var(--color-black-70);
}

.__react_component_tooltip.type-dark.place-top:after {
    border-top-color: var(--color-black-70);
    border-top-style: solid;
    border-top-width: 6px;
}



// .c-tooltips{
//     position:fixed;
//     background-color: var(--color-black-90);
//     padding:8px 16px;
//     color:#fff;
//     border-radius:4px;
//     top:0;
//     left:0;
// }

// .c-tooltips span{
//     opacity:0.7;
// }

// .c-tooltips:after{
//     height:9px;
//     width:9px;;
//     display:block;
//     background:var(--color-black-90);
//     transform:rotate(45deg) translateX(-50%);
//     content:'';
//     position:relative;
//     bottom:0;
// }