/* title fonts*/
.title{
    margin:30px 0px 20px;
    position:relative;
    display:inline-block;
    padding-bottom:10px;
    font:var(--font-h2b-32);
    &:first-letter {
        text-transform: uppercase;
    }
}

.title:after{
    content:'';
    display:block;
    background:var(--color-blue-500);
    height:2px;
    display:block;
    bottom:0;
    lefT:0;
    right:0;
    position: absolute;
}