.icons{
    height:32px;
    width:32px;
    background-repeat:no-repeat;
    background-position: 50% 50%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    background-size:contain;
    cursor:pointer;
    display:inline-block;
}

.icons._vsmall{
    height:16px;
    width:16px;
}

.icons._small{
    height:24px;
    width:24px;
}

.icons._alert{
    height:100px;
    width:100px;
}

.icons._opacity{
    opacity:0.2;
}

.icons._gray{
    filter: grayscale(1);
    &:hover{
        filter: grayscale(0);
    }
}

/*editor*/
.icons._bold{background-image:url(/img/editor/bold.svg);}
.icons._list{background-image:url(/img/editor/list.svg);}

// like
.icons._star{
    transition: all 0.2s ease;
    background-image:url(/img/icons/star.svg);
    transform-origin: center;
    &._half{
        background-image:url(/img/icons/star_half.svg);
    }
    &:hover{
        transform:scale(0.88);
    }
    &:active {
        transform:scale(0.6);
    }
    &.active{
        background-image:url(/img/icons/star_active.svg);
    }
}

// favorite
.icons._heart{
    transition: all 0.2s ease;
    background-image:url(/img/icons/heart.svg);
    transform-origin: center;
    &:hover{
        transform:scale(0.88);
    }
    &:active {
        transform:scale(0.6);
    }
    &.active{
        background-image:url(/img/icons/heart_active.svg);
    }
}

/*simple*/
.icons._play{background-image:url(/img/icons/play.svg);}
.icons._stop{background-image:url(/img/icons/stop.svg);}
.icons._period{background-image:url(/img/icons/period.svg);}
.icons._share{background-image:url(/img/icons/share.svg);}
.icons._del{background-image:url(/img/icons/delete.svg);}
.icons._edit{background-image:url(/img/icons/edit.svg);}
.icons._map{background-image:url(/img/icons/map.svg);}
.icons._check{background-image:url(/img/icons/check.svg);}
.icons._data{background-image:url(/img/icons/callendar.svg);}
.icons._clock{background-image:url(/img/icons/clock.svg);}
.icons._web{background-image:url(/img/icons/web.svg);}
.icons._enter{background-image:url(/img/icons/enter.svg);}
.icons._like{background-image:url(/img/icons/like.svg);}
.icons._sg{background-image:url(/img/icons/sg.svg);}

//.icons._globe{background-image:url(/img/icons/globe.svg);}
.icons._email{background-image:url(/img/icons/email.svg);}
.icons._phone{background-image:url(/img/icons/phone.svg);}
.icons._home{background-image:url(/img/icons/home.svg);}
.icons._bag{background-image:url(/img/icons/bag.svg);}
.icons._money{background-image:url(/img/icons/money.svg);}
.icons._reply{background-image:url(/img/icons/reply.svg);}
.icons._back{background-image:url(/img/icons/back.svg);}
.icons._attach{background-image:url(/img/icons/attach.svg);}
.icons._file{background-image:url(/img/icons/file.svg);}
.icons._send{background-image:url(/img/icons/send.svg);}
.icons._close{background-image:url(/img/icons/close.svg);}
.icons._user{-webkit-mask-image:url(/img/icons/user.svg);background-color:#fff}
.icons._user._black{background-color:#000}
.icons._user._big{height:64px;width:64px;}
.icons._company{-webkit-mask-image:url(/img/icons/company.svg);background-color:#fff}
.icons._out{-webkit-mask-image:url(/img/icons/out.svg);background-color: var(--color-blue-500);}

.icons._close{
    background-image:url(/img/icons/close.svg);
    height:24px;
    width:24px;
}

/* inputs */
.icons._eye{background-image:url(/img/icons/inputs/eye.svg);}
.icons._eye.active{background-image:url(/img/icons/inputs/eyeClose.svg);}
.icons._eye.hide{filter:grayscale;opacity:0.5;}
.icons._cal{background-image:url(/img/icons/inputs/cal.svg);}
.icons._arrow{background-image:url(/img/icons/inputs/arrow.svg);}
//!
//.icons._arrow-up{background-image:url(/img/icons/inputs/arrowUp.svg);}
.icons._euro{background-image:url(/img/icons/inputs/eur.svg);}

/* soc inputs*/
.icons._yt{background-image:url(/img/soc/color/yt.svg);}
.icons._tw{background-image:url(/img/soc/color/tw.svg);}
.icons._gl{background-image:url(/img/soc/color/gl.svg);}
.icons._li{background-image:url(/img/soc/color/li.svg);}
.icons._xi{background-image:url(/img/soc/color/xi.svg);}
.icons._fb{background-image:url(/img/soc/color/fb.svg);}

// soc detail page
.icons._det-yt{background-image:url(/img/det/soc/yt.svg);}
.icons._det-tw{background-image:url(/img/det/soc/tw.svg);}
.icons._det-li{background-image:url(/img/det/soc/li.svg);}
.icons._det-xi{background-image:url(/img/det/soc/xi.svg);}
.icons._det-fb{background-image:url(/img/det/soc/fb.svg);}
.icons._det-gl{background-image:url(/img/det/soc/gl.svg);}

// alert
.icons._alert-ok{background-image:url(/img/alert/ok.svg);}
.icons._alert-error{background-image:url(/img/alert/error.svg);}
.icons._alert-arh{background-image:url(/img/alert/arh.svg);}

/* menu */
.icons._menu{
    height:56px;
    width:56px;
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;   
}

.icons._side-menu{
    height:24px;
    width:24px;
}

.icons._topmenu{
    background-image:url(/img/icons/menu.svg);
    background-size: 24px 24px;
}