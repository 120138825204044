.mes-del{
    width:100%;
    max-width: 300px;
    display:grid;
    grid-template-columns: 1fr;
    grid-gap:30px;
}

.mes-del-text{
    padding-bottom:20px;
}

.mes-del-button{
    display:grid;
    grid-gap:20px;
    grid-template-columns:repeat(2,1fr);
}