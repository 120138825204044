.react-select{
    font:var(--font-text3-18);
    .select__control{
        border: 1px solid var(--color-black-50);
        border-radius: 2px;
        position: relative;
    }
    .select__control--is-focused{
        border: 1px solid var(--color-blue-500);
        box-shadow:none;
    }
    .select__indicator-separator{
        display:none;
    }
    .select__menu{
        margin:0;
        box-shadow: 0px 0px 10px rgba(34, 34, 34, 0.2);
        border-radius: 0;
        padding:0;
        z-index:100;
    }
    .select__value-container{
        padding:5px;
    }
    .select__menu-list{
        margin:0px;
        padding:0px;
    }
    .react-select__option{
        cursor:pointer;
    }
    .select__placeholder{
        white-space: wrap;
    }
    input{
        padding:0px !important;
    }
    .select__multi-value__label{
        white-space: unset;
    }
}