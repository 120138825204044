/* table*/
.fr-ls-table{
    display:grid;
    width: 100%;
    grid-template-columns: 1fr auto 0fr;
    position:relative;
    align-items: center;
}

.fr-ls-title,
.fr-ls-row{
    display:contents;
    width:100%;
}

.fr-ls-title>div{
    border-bottom:2px solid #ccc;
    font:var(--font-h6b-16);
    align-self: flex-end;
}

.fr-ls-title>div,
.fr-ls-row>div{
    padding:16px;
}