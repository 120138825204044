.alert{
    background: var(--color-green-lable);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 4px;
    position: absolute;
    color: #fff;
    display: flex;
    justify-content: space-between;
    grid-gap: 20px;
    padding: 10px;
    margin-top: 0px;
    z-index: 1000;
    transform: translateX(-50%);
    left: 50%;
    width:90vw;
    max-width: 1000px;
    font:var(--font-text3-18);
    align-items: center;
    opacity:0;
    transition:opacity .3s, margin-top 0.3s;
}

.alert.anim{
    margin-top: 20px;
    opacity:1;
}