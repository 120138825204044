@import '../css/var.scss';
/*bottom*/
.page-bottom{
    background:var(--color-dark-500);
    padding-top:20px;
    color:var(--color-white);
}

.page-bottom-content{
    display:flex;
    justify-content: stretch;
    @media(max-width:$pad){
        flex-direction: column-reverse;
    }
}

.page-bottom-logo{
    display:inline-block;
    height:54px;
    width:54px;
    background:url(/img/logo/logo.png) no-repeat 50% 50%;
    background-size:contain;
}

.page-bottom-contacts{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    margin:24px 0px;
}

/* menu button*/
.page-bottom-menu{
    display:flex;
    width:100%;
    margin-bottom: 20px;
    @media(max-width:$mobile){
        flex-direction: column;
    }
}

.page-bottom-item{
    margin-right:40px;
    flex:1 auto;
    @media(max-width:$mobile){
        margin-left:0;
        margin-bottom:30px;
    }
}

.page-bottom-list{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}

.page-bottom-link{
    display:block;
    color:var(--color-black-30);
    margin-bottom:5px;
    position:relative;
    padding-bottom:6px;
    transition:0.3s;
    font:var(--font-text4-16);
    &:after{
        content: '';
        background:var(--color-blue-500);
        display:block;
        position:absolute;
        height:1px;
        width: 0%;
        transition:1s;
        bottom:0;
        left:0;
    }
    &:hover{
        color:#fff;
    }
    &:hover::after{
        width: 100%;
    }
}

.page-bottom-title{
    position:relative;
    margin-bottom:12px;
    padding-bottom:7px;
}

.page-bottom-title:after{
    content:'';
    display:block;
    height:3px;
    width:40px;
    background:#0F8BFF;
    position:absolute;
    bottom:0;
    left:0;
}

/* copyright*/

.page-copyright{
    padding:5px 0px;
    background:rgba(34, 34, 34, 0.8);
    color:808080;
    margin-top:20px;
}

// feedback
.page-feedback-title{
    color:var(--color-white);
    margin-bottom:5px;
}

.page-feedback-form{
    display:flex;
    grid-gap:5px;
}

.page-feedback-submit{
    height:100%;
    width:30px;
    background:none;
    display: block;
    background:#29334f;
    border-radius:3px;
    padding-bottom:5px;
    &:hover{
        background:#303c5d;
    }
    &:active{
        background:#40527f;
    }
    &:before{
        color:#fff;
        display:block;
        content:'\00bb';
        font-size: 20px;
        font-weight: bold;
    }
}

// page soc
.page-soc{
    display:flex;
    flex:1;
    justify-content: flex-start;
    margin:20px 0px;
    grid-gap:10px;
}

.page-soc-item{
    height:35px;
    width: 35px;
    border:1px solid #404550;
    border-radius:4px;
    transition:0.2s;
    &:hover{
        background-color: #7b8395;
        border-color:#ffffff00;
    }
}

.soc{
    height:100%;
    width:100%;
    background-repeat:no-repeat;
    background-position: center;
    background-size:contain;
    display:block;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 24px auto;
    -webkit-mask-position: 50% 50%;
    background-color: #7b8395;
    &:hover{
        background-color: var(--color-dark-500);
    }
}

.soc-fb{-webkit-mask-image:url(/img/main/fb.svg);}
.soc-li{-webkit-mask-image:url(/img/main/li.svg);}
.soc-xi{-webkit-mask-image:url(/img/main/xi.svg);}
.soc-yt{-webkit-mask-image:url(/img/main/yt.svg);}