@import '../css/var.scss';
.item-title{
    font:var(--font-h3b-24)
}
.item{
    cursor:pointer;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(34, 34, 34, 0.3);
    border-radius: 4px;
    padding:26px 24px;
    display:flex;
    align-items: flex-start;
    margin-bottom:16px;
    color:#000;
    &:hover{
        box-shadow: 0px 0px 10px rgba(34, 34, 34, 0.3);
    }
    @media(max-width:$pad){
        flex-direction: column;
        grid-gap: 20px;
    }
}

.item-line{
    background:var(--color-black-30);
    flex: 0 0 1px;
    align-self: stretch;
    margin:0 24px;
    @media(max-width:$pad){
        margin:0;
    }
}

.item-logo{
    height:100px;
    width:100px;
    border-radius:4px;
    overflow: hidden;   
    background: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    &._proj{
        width:64px;
        height:64px;
        border:1px solid #ccc;
    }
    &._foto{
        border-radius:100%;
    }
}

.item-logo img{
    object-fit: contain;
    height:100%;
    width:100%;
}

.item-location{
    position:relative;
    color:var(--color-black-50);
    display:flex;
    align-items: center;
    margin:5px 0px;
}

.item-icons{
    display:flex;
}

.item-info{
    flex:1;
}

.item-info-top{
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
}

.item-status{
    display:flex;
}

// .item-logo.item-logo{
//     border-radius:4px;
//     display:flex;
//     justify-content: center;
//     align-items: center;
//     background:#f0f0f0;
// }

// .item-logo.item-foto{
//     border-radius:100%;
//     display:flex;
//     justify-content: center;
//     align-items: center;
//     background:#f0f0f0;
// }

.item-freelancer-title{
    display:flex;
}

.item-freelancer-status{
    margin-left:10px;
    display: flex;
    grid-gap:10px;
    align-items: center;
}

.item-freelancer-work{
    margin:8px 0px;
    >a{
        margin-right:20px;
    }
}

.item-freelancer-skils{
    display:flex;
    grid-gap:10px;
    >div{
        padding-right:10px;
        position:relative;
        &:after{
            content:'';
            display:block;
            background:#25AFF8;
            position: absolute;
            width:1px;
            height:100%;
            top:0;
            right:0;
        }
        &:last-child{
            &:after{
                display:none;
            }
        }
    }
}

.item-freelancer-live{
    display:flex;
    grid-gap:18px;
    margin-top:8px;
}

.item-freelancer-info{
    display:flex;
    align-items:center;
}

/*proj*/
.item-proj-info{
    display: flex;
    align-items: center;
    margin:10px 0px;
}

.item-descr{
    margin: 10px 0px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item-proj-right{
    display:flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items: flex-end;
    grid-gap:20px;
}