@import '../css/var.scss';
/* wrapper*/
.page-wrapper{
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.page-main{
    flex:1;
    &._simple{
        position:relative;
        background:url('/img/page/simple/mountain.jpeg') no-repeat 50% 100%;
        background-attachment: fixed;
        background-size:100% auto;
        &:before{
            content: '';
            display:block;
            position:fixed;
            top:60px;
            left:0;
            height:200px;
            width: 100%;
            background: linear-gradient(180deg,#f2f7fd,#f2f7fd00);
            z-index:0
        }
    }
}

// callback
.callback{
    background: url(/img/soc/messager.svg) no-repeat 50% 50%;
    background-size:contain;
    height:60px;
    width:60px;
    position:fixed;
    bottom:40px;
    right:30px;
    z-index:100;
}

/* main size*/
.page-block{
    margin:0 auto;
    max-width:1200px;
    width:100%;
    padding:0 20px;
    &._small{
        max-width:1000px
    }
    &._big{
        max-width:1600px
    }
}

.page-title{
    margin-bottom: 24px;
}

/* main icons*/

.page-content{
    padding:70px 0px;
    text-align:center;
    &._dark{
        background:var(--color-dark-500);
    }
    @media(max-width:$pad){
        padding:40px 0px 60px;
    }
    &._white{
        background:#fff;
    }
    &._gray{
        background:#F7F8FA;
    }
    &._notop{
        padding-top:0px;
    }
}

.page-content-text{
    width:100%;
    margin:0 auto;
    margin-bottom:30px;
    >.font-h1b-50{
        margin-bottom:20px;
    }
    ._dark &{
        color:#fff;
    }
}

.font-text1-32._hide{
    opacity:.5;
}