@import '../../css/var.scss';

.contacts-content{
    padding:24px 0px;
}

.contacts-block{
    display:grid;
    grid-gap:40px 80px;
    grid-template-columns: 2fr 3fr;
    @media (max-width:$pad){
        grid-template-columns:1fr;
    }
}

/* contacts info*/
.contacts-logo{
    height:80px;
    margin-top:40px;
    width:100%;
    max-width: 300px;
    @media (max-width:$pad){
        display:none;
    }
}

.contacts-logo img{
    object-fit: contain;
    object-position: left center;
    height:100%;
    width: 100%;
}

.contacts-info{
    margin-top:48px;
    font-size: 1.2rem;
}
.contacts-info-item{
    display:grid;
    align-items: flex-start;
    grid-template-columns: 32px auto;
    grid-gap:10px;
    margin-bottom:16px;
    position:relative;
    >.icons{
        margin-top:-5px;

    }
}

/* contacts form*/
.contacts-res{
    height:100%;
    display:flex;
    align-items: center;
    .button{
        margin-top:30px;
    }
}
.contacts-form{
    padding:32px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
}

.contacts-title{
    margin-bottom:32px;
}

.contacts-form-input{
    margin-bottom:24px;
    &:last-child{
        margin-bottom:0px;
    }
}

.contacts-form-bottom{
    display:flex;
    align-items: flex-end;
    justify-content: space-between;
    @media (max-width:$pad){
        flex-direction: column;
        align-items: unset;
    }
}

.contacts-form-small{
    font-size:12px;
    margin-left:40px;
    @media (max-width:$pad){
        margin: 0;
        margin-top:20px;
        text-align: center;
    }
}