.mes-premium{
   display:block;
}

.mes-premium-title{
    font:var(--font-h3b-24);
}

.mes-premium-content{
    display: grid;
    grid-gap:15px;
    font: var(--font-text5-14);
    max-width: 300px;
}

.mes-premium-price{
    font:var(--font-h5b-18);
    display:grid;
    margin:10px 0px;
    &>div{
        display:flex;
        justify-content: space-between;
        padding:5px 0px;
    }
}

.mes-premium-sum{
    margin-top:10px;
    border-top:1px solid #555;
}