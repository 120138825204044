@import "../../css/var.scss";

.other-banner{
    top:0;
    background:url(/img/banner.jpeg) no-repeat 50% 50%;
    background-size:cover;
    position:relative;
    z-index:1;
    padding:25px 0px;
    color:#fff;
    &:after{
        position: absolute;
        z-index:0;
        background: var(--color-dark-500);
        content: '';
        display:block;
        top:0;
        left:0;
        width:100%;
        height:100%;
        opacity: 0.85;
    }
}

.other-banner-block{
    position: relative;
    z-index:1;
}

.other-banner-title{
    font:var(--font-h2b-32);
    text-align:center;
    margin:20px auto 10px;
    @media(max-width:$mobile){
        font:var(--font-h4b-20);
    }
}

.other-form{
    margin-top:25px;
}

/*other content*/
.other-block{
    display:flex;
    margin:32px 0px
}

.other-menu{
    flex:0 0 255px;
    margin-right:30px;
    @media(max-width:$mobile){
        position: absolute;
        background: #fff;
        padding: 20px;
        box-shadow: 0 0 10px rgb(0 0 0 / 40%);
        z-index: 1;
        display: block;
        flex: 1;
        margin-right: auto;
        left: 20px;
        right: 20px;
        display:none;
        &.active{
            display:block;
        }
    }
}

.other-menu>div{
    margin-bottom:24px;
}

.other-menu-title{
    margin-bottom:8px;
    font:var(--font-h2-24);
}

.other-content{
    flex:1;
    display:flex;
    flex-direction: column;
    position:relative;
}

.other-content-block{
    cursor:pointer;
    display:block;
    height:100%;
    width:100%;
    z-index:1;
    position:absolute;
    top:0;
    left:0;
}

.other-flex{
    flex:1;
    width:100%;
}

.other-char{
    margin-bottom:20px;
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.other-filter{
    flex:0 0 32px;
    margin-right:30px;
    display:none;
    @media(max-width:$mobile){
        display:block;
    }
}

.other-noresult{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    border: 2px solid var(--color-blue-500);
    color: var(--coloe-blue-600);
}

// Freelance List

.other-content-top{
    display:flex;
    justify-content: space-between;
    margin-bottom:20px;
    align-items:center;
}

.other-content-select{
    display:flex;
    align-items:center;
}

.other-content-choise{
    margin-left:16px;
}

//Project list
.other-content-top{
    display:flex;
    justify-content: space-between;
    margin-bottom:20px;
    align-items:center;
}

.other-content-select{
    display:flex;
    align-items:center;
}

.other-content-choise{
    margin-left:16px;
}

.other-checkbox{
    display:flex;
    flex-direction: column;
    grid-gap:10px;
    margin-bottom:10px;
}

/* spam */
.other-spam{
    border:2px solid var(--color-blue-500);
    border-radius:4px;
    padding:24px;
    display:grid;
    grid-gap:10px;
    .button{
        margin-top:10px;
    }
}
