.rubric{
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    align-items: stretch;
    margin-top:40px;
}

.rubric-item{
    display:flex;
    background: #FFFFFF;
    color:#000000;
    box-shadow: 0px 20px 30px rgba(42, 62, 114, 0.2);
    border-radius: 4px;
    margin:10px;
    padding:10px 20px;
    height:60px;
    position:relative;
    max-width:280px;
    align-items: center;
    @media(max-width:$pad){
        max-width:unset;
        width: 100%;
        justify-content: center;
        height: auto;
        box-shadow: 0px 0px 15px rgba(42, 62, 114, 0.2);
    }
}