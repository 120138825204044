.game{
    height:100%;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    opacity:0;
    transition:opacity 2s;
    padding:40px;
    &.active{
        opacity:1;
    }
}

.game-title{
    font-size:30px;
    font-weight: 600;
    text-align: center;
}

.game-content{
    display:block;
    flex:1;
}

.game-grid{
    flex: 1 1;
    display: grid;
    grid-gap: 8px;
    margin: 40px auto;
    width: 680px;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 60px);
}

.game-card{
    height:70px;
    color:#000;
    font-size:20px;
    font-weight: 600;
    background:#fff;
    padding:15px 10px;
    text-align: center;
    cursor:pointer;
    box-shadow: 0 0 5px rgba(0,0,0,.2);
    border-radius:5px;
    transition:0.3s;
    transform-origin: center;
    transform-style:preserve-3d;
    position:relative;
    .game-card-back,
    .game-card-front{
        top:0;
        left:0; 
        position:absolute;
        display:block;
        height:100%;
        width: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-radius: 5px;
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .game-card-back{
        background:#fff;
        transform:rotateY(180deg);
    }
    .game-card-front{
        background:linear-gradient(180deg, #51c3e3, #31addc);
    }
    &:hover{
        box-shadow: 0 0 10px rgba(0,0,0,.2);
    }
    // hode shown
    &.active{
        transform:rotateY(180deg);
    }
    // for error
    &.error{
        box-shadow: 0 0 5px rgb(171 17 17 / 50%)
    }
    &.correct{
        box-shadow: 0 0 5px rgb(70 171 17 / 50%)
    }
}

.game-score{
    font-size:30px;
    text-align: center;
    font-weight: bold;
}