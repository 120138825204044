.swiper{
    padding-bottom:50px;
    //height:100%;
}

.swiper-wrapper{
    align-items: stretch;
}

.swiper-block{
    margin:30px auto;
}

.swiper-slide{
    height:auto;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    padding:15px;
    // .home-company &{
    //     height:auto;        
    // }
}

.swiper-horizontal>.swiper-pagination-bullets, 
.swiper-pagination-bullets.swiper-pagination-horizontal, 
.swiper-pagination-custom, 
.swiper-pagination-fraction{
    bottom:20px;
    margin:40px auto 0;
    user-select: none;
}

.swiper-pagination-bullet{
    opacity:1;
    cursor:pointer;
    border-radius:100%;
    height:12px;
    width:12px;
    background-color:#E6E6E6;
    margin:8px;
    &:hover{
        background-color:#cccccc;
    }
    &.swiper-pagination-bullet-active{
        background-color:#272D3D
    }
}