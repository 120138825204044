
/*home-banner form*/

.search-form{
    border-radius:4px;
    background:var(--color-white);
    box-shadow: 0px 13px 24px rgba(8, 36, 107, 0.13);
    display:flex;
    height:50px;
    align-items: stretch;
    width:100%;
    margin:20px 0px;
}

.search-form-input{
    flex:1;
    display:flex;
    padding:0px 20px;
}