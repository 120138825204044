.fr-pr{
    display:contents;
}

.fr-pr-top{
    background:var(--color-blue-50);
    padding:20px 40px;
    display:grid;
    grid-template-columns: repeat(3,1fr);   
    grid-gap:20px;
    margin-bottom:30px;
}

.fr-pr-item{
    text-align:center;
}

.fr-pr-number{
    font:var(--font-h1b-50);
    color:var(--color-blue-light);
    margin-bottom:8px;
}

.fr-pr-text{
    font:var(--fonr-h3-18);
}