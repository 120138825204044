@import "../css/var.scss";

.menu-account{
    display:flex;
    align-items: center;
    @media(max-width:$pad){
        display:none;
    }
}

.menu-mail{
    display:block;
    margin-right:10px;
    position:relative;
    height:45px;
    width:60px;
    a{
        background:url(/img/email.svg) no-repeat 50% 50%;
        background-size:22px auto;
        display:block;
        height: 100%;
        width: 100%;
    }
    span{
        display:flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position:absolute;
        z-index:1;
        box-shadow:0 0 7px rgba(0,0,0,.3);
        height:18px;
        width: 18px;
        background:#fff;
        color:#06132b;
        top:7px;
        left: 28px;
        border-radius:100%;
        font-weight:900;
        font-size:10px;
        font-family: Arial, Helvetica, sans-serif;
    }
}

.menu-account-ico{
    display:none;
    @media(max-width:$pad){
        display:block;
    }
}

.menu-account-out{
    margin-left:10px;
}

// loged
.menu-account-in{
    display:flex;
}

.menu-account-foto{
    display:flex;
    height:45px;
    width:45px;
    overflow:hidden;
    background:#f0f0f0;
    border-radius:100%;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}

.menu-account-foto img{
    object-fit: cover;
    height:100%;
    width: 100%;
}