.mes{
    display:flex;
    align-items: center;
    justify-content: center;
    position:fixed;
    z-index: 1000;
    height:100vh;
    width:100vw;
    top:0;
    left:0;
}

.mes:before{
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0, 0, 0, 0.4);
}

.mes-content{   
    background:#fff;
    padding:60px 40px 60px;
    position:relative;
}   

.mes-title{
    font:var(--font-h2b-32);
}

.mes-top{
    margin:30px 0px;
}

.mes-title._center{
    text-align:center;
}

.mes-title-small{
    font:var(--font-h5b-18);
}

.mes-link{
    padding:30px 0px;
    text-align:center;
}

.mes .button{
    width:100%;
}

.mes-close{
    height:40px;
    width:40px;
    position:absolute;
    top:0;
    right:0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
}