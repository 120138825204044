.back{
    position:relative;
    color:var(--color-blue-500);
    text-transform: uppercase;
    padding-left:32px;
    display:flex;
    align-items:center;
    cursor:pointer;
    font:var(--font-button1-16);
}

.back:after{
    display: block;
    background: url(/img/icons/back.svg) no-repeat 0% 50%;
    position: absolute;
    background-size: contain;
    content: '';
    height: 40px;
    width: 32px;
    position: absolute;
    left: 0;
}